import Fotos from "src/components/Fotos";
import Footer from "src/components/Footer";
import Noticias from "src/components/Noticias";
import Header from "src/components/Header";

const App = () => {
  return (
    <div className={"bg-gray-600 min-h-screen text-white"}>
      <Header />
      <Noticias />
      <Fotos />
      <Footer />
    </div>
  );
};
export default App;
