// @ts-ignore
import fotoBanda from "src/fotos/banda.jpg";
// @ts-ignore
import fotoBanda2 from "src/fotos/rock.jpg";
// @ts-ignore
import tapaAlbum from "src/fotos/raramezcla.jpg";

const ImageBackground = ({ src, children = null }) => (
  <div
    style={{ backgroundImage: `url(${src})` }}
    className={
      "min-h-screen flex items-center justify-center bg-cover bg-black bg-opacity-40 bg-blend-overlay bg-center"
    }
  >
    <div
      className={
        "flex w-full flex-col items-center justify-center p-16 space-y-16"
      }
    >
      {children}
    </div>
  </div>
);

const Titulo = ({ children }) => (
  <div className={"text-3xl xl:text-8xl m-8"}>{children}</div>
);

const Noticias = () => (
  <div className={"text-center"}>
    <ImageBackground src={fotoBanda}>
      <div className={"flex flex-col xl:flex-row"}>
        <Titulo>Mira el nuevo video de Ni de Favor feat. Palo Pandolfo</Titulo>

        <iframe
          className={"w-full max-w-[800] min-h-[40vh]"}
          src="https://www.youtube-nocookie.com/embed/Gg4ekU_jihs"
          title="Sensafilo - Ni de favor"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </ImageBackground>

    <ImageBackground src={fotoBanda2}>
      <div className={"flex items-center flex-col xl:flex-row"}>
        <Titulo>Escucha nuestro último disco en Spotify</Titulo>
        <img
          src={tapaAlbum}
          className={"flex self-center w-full max-w-[400]"}
          alt={"Tapa de álbum de Rara Mezcla"}
        />
      </div>
      <iframe
        className={"w-full h-[390px]"}
        src="https://open.spotify.com/embed/album/1pPfe78ySpPov7FLThXTaU?utm_source=generator"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </ImageBackground>
  </div>
);

export default Noticias;
