// @ts-ignore
import images from "../fotos/miembros/*.jpg";
import _ from "lodash";

const Fotos = () => (
  <div className={"flex flex-row justify-center"}>
    {_.map(images, (value, key) => (
      <img className="w-1/4" key={key} src={value} alt={key} />
    ))}
  </div>
);
export default Fotos;
