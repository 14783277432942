// @ts-ignore
import logo from "src/fotos/logo.png";
import { SocialIcon } from "react-social-icons";

const REDES = [
  "https://instagram.com/sensafilo",
  "https://open.spotify.com/artist/4yMV1JSsg4oQ8WhGiN5law",
  "https://linktr.ee/sensafilo",
  "https://www.youtube.com/channel/UCwPUgNcz9fUHqE10PxYUJoQ",
];

const Header = () => (
  <nav className="bg-orange-400 flex items-center justify-center py-2 px-4 h-[15vh] xl:h-[20vh]">
    <img className={"h-full"} src={logo} alt={"Sensafilo"} />
    <div className={"flex flex-1 justify-end items-center h-full space-x-2"}>
      {REDES.map((it) => (
        <SocialIcon
          className={"!w-10 !h-10 xl:!w-24 xl:!h-24 ml-2"}
          key={it}
          target="_blank"
          url={it}
          bgColor={"black"}
          fgColor={"white"}
        />
      ))}
    </div>
  </nav>
);

export default Header;
